import { Router } from '@reach/router';
import React, { memo, useMemo } from 'react';
import Wrapper from '../components/shared/Wrapper';

const Resume = () => {
  useMemo(() => <div id="resume">Welcome, You are on Resume page.</div>);
};

const ResumeRouter1 = () => (
  <Wrapper>
    <Router>
      <Resume path="/resume/:id" />
    </Router>
  </Wrapper>
);
export default memo(ResumeRouter1);
